@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D88B0F; /* Scrollbar thumb color */
    border-radius: 6px; /* Round edges */
    border: 3px solid transparent; /* Adds some padding around the thumb */
    background-clip: padding-box; /* Ensures border doesn't overlap */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #c07a0e; /* Darker shade for hover effect */
  }

  ::-webkit-scrollbar-track {
    background-color: #27272A; /* Background color of the scrollbar track */
  }

body{
  font-family: 'Roboto';
  }

.auth-label{
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 0 5px;
  transition: transform 0.3s ease, color 0.3s ease;
  color: white;
  opacity: 0.6;
  border-radius: 2px;
}

input:focus + .auth-label,
input:not(:placeholder-shown) + .auth-label {
  transform: translateY(-20px);
  background: #000;
  color: #D88B0F;
  opacity: 0.9;
  font-size: small;
  font-weight: 400;
}

